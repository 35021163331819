import React from 'react'

import ContactsForm from './ContactsForm/ContactsForm'
import ContactsInfo from './ContactsInfo/ContactsInfo'

import { className } from '../../../Utils'

import './ContactsContent.css'
import { useSelector } from 'react-redux'

const ContactsContent = () => {
    const { language } = useSelector((state) => state.home)
    return (
        <div className={className('contacts-content-wrapper') + ' ' + className(language !== 'bg' && 'contacts-wrapper')}>
            <ContactsInfo />
            <ContactsForm />
        </div>
    )
}

export default ContactsContent