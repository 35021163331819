import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  listAll,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db, storage } from "./firebase/firebaseInit";
import { getAllSponsors, setHomeTranslations } from "./store/actions/homeActions";
import { setError } from "./store/actions/errorActions";
import { downloadAppCounter } from "./store/actions/userActions";
import { SERVER_URL } from "./config/serverUrl";

export async function getFirestoreData(uid) {
  // const q = query(collection(db, `users`))//, where("uid", "==", uid)
  //  const unsub = await onSnapshot(q, (querySnapshot) => {
  //   console.log("Data", querySnapshot.docs.map(d => d.data()));
  // });

  //why this stop working ?
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // console.log("getFirestoreData:", docSnap.data());
    return docSnap.data();
  } else {
    // console.log("No such firestore data!");
    // doc.data() will be undefined in this case
    return {};
  }
}

export async function removeFirestoreData(uid) {
  await deleteDoc(doc(db, "users", uid))
    .then((res) => {
      // console.log('removeFirestoreData for ', uid, res);
    })
    .catch((e) => {
      //no need for now
      //setError(e.code);
    });
}

export async function addFirestoreData(uid, data) {
  await setDoc(doc(db, "users", uid), data)
    .then((res) => {
      // console.log('addFirestoreData -> ', data);
    })
    .catch((e) => {
      setError(e.code);
    });
}
export async function updateFirestoreData(uid, data) {
  await updateDoc(doc(db, "users", uid), data)
    .then((res) => {
      // console.log('updateFirestoreData -> ', data);
    })
    .catch((e) => {
      setError(e.code);
    });
}

export async function getCollection() {
  const querySnapshot = await getDocs(collection(db, "feia-community"));
  let res = [];
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    res = [...res, data];
  });
  // console.log("users ", res);

  return res;
}

export async function getAllCollections() {
  let storage = getStorage();
  let listRef = ref(storage, "/");
  return await listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        // All the items under listRef.
      });
    })
    .catch((error) => {
      // console.log("Uh-oh, an error occurred!", error);
      //
    });
}

export function allSposors(lang) {
  return async (dispatch) => {
    const data = await fetch(`${SERVER_URL}/admin/all-website-logos`);
    const response = await data.json();

    if (response.code !== 404) {
      const dataWithImageUrl = await imageUrl(response)
      dispatch(getAllSponsors(dataWithImageUrl))
    }
  }
}

const imageUrl = async (response) => {
  const resultArray = [];

  try {

    for (let item of response) {


      const imageUrl = await getFileUrl('web-logo', item?.image_name)
      if (imageUrl === undefined) {
        return []
      } else {
        resultArray.push({
          ...item,
          imageUrl
        })
      }
    }
    return resultArray
  } catch (error) {
    console.log(error.message)
  }

}

export function getAllRegisterdUsers() {
  return async (dispatch) => {
    const data = await fetch(`${SERVER_URL}/admin/all-registered-users`);
    const response = await data.json();
    dispatch(downloadAppCounter(response))
  }
}

//v8 old method
export const updateUserData = async (userId, updateData) => {
  await storage.collection("users").doc(userId).update(updateData);
  // console.log("User data updated!");
  // console.log(updateData);
};

export const uploadToCloud = (file, path, onSucces) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => { },
    (error) => {
      console.log(error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        onSucces(downloadURL);
      });
    }
  );
};


const getFileUrl = async (folder, fileName) => {
  try {
    let url = ''
    const imageRef = ref(storage, `${folder}/${fileName}`);
    if (imageRef) {
      url = await getDownloadURL(imageRef)
    }

    return url
  } catch (error) {
    console.log(error.message)
  }
};

export const getTerms = async (lang) => {
  try {
    const data = await fetch(`${SERVER_URL}/admin/terms/${lang}`);
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
    throw error; // Throw the error to propagate it to the caller
  }
};

export function getAllTranslations(language) {
  return async (dispatch) => {
    const data = await fetch(`${SERVER_URL}/admin/website-translations/${language}`);
    const response = await data.json();

    dispatch(setHomeTranslations(response))

  }

}
