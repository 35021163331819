import { isMobile } from "react-device-detect";

import { useCallback, useEffect, useState } from "react";

import windowsIcon from "../../assets/App_Store.png";
import IOSIcon from "../../assets/IOS-icon.png";
import "./OperationSystem.css";

const OperationSystem = ({ currentHeight }) => {
  const [showIcon, setShowIcon] = useState(false);

  const showOP = useCallback(() => {
    if (currentHeight >= 540) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [currentHeight]);

  useEffect(() => {
    showOP();
  }, [currentHeight, showOP]);

  if (isMobile) {
    return (
      <>
        {showIcon && (
          <div className="icon-container-Mobile">
            {window.navigator.userAgent.indexOf('Mac OS') !== -1
              ? (
                <a
                  href='https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1'
                  target="_blank"
                  rel="noreferrer"
                  title="IOS store"
                >
                  <img
                    src={IOSIcon}
                    alt="icon"
                    className="icon-img-Mobile"
                    loading="lazy"
                  />
                </a>
              ) :
              (<a
                href='https://play.google.com/store/apps/details?id=bg.feia.Pregnancy'
                target="_blank"
                rel="noreferrer"
                title="Play Store"
              >
                <img
                  src={windowsIcon}
                  alt="icon"
                  className="icon-img-Mobile"
                  loading="lazy"
                />
              </a>
              )}
          </div>
        )}
      </>
    );
  }
  if (!isMobile) {
    return (
      <>
        {showIcon && (
          <div className="icon-container">
            <div className="android-container"  >
              <a
                href='https://play.google.com/store/apps/details?id=bg.feia.Pregnancy'
                target="_blank"
                rel="noreferrer"
              >
                <img src={windowsIcon} alt="icon" className="icon-img" />
              </a>
            </div>
            <div className="ios-container">
              <a
                href='https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1'
                target="_blank"
                rel="noreferrer"
              >
                <img src={IOSIcon} alt="icon" className="icon-img" />
              </a>
            </div>
          </div>
        )
        }
      </>
    );
  }
};

export default OperationSystem;
