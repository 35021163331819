
import { Link } from "react-router-dom";
import { className } from "../../../Utils";
import { useTranslations } from "../../../lang/TranslationHooks";
import phone from '../assets/webp/phone-home-BG.webp'
import flowerWith from '../assets/webp/cloudsHeader-2.webp'
import "./Welcome.css";

const Wellcome = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('welcome-page')} id='section00'>
            <div
                className={className('welcome-wrapper')}
                style={{
                    display: 'flex'
                }}>
                <div className={className('content-container')}>
                    <p className={className('welcome-subtitle')}>
                        {staticTranslate('t_home_header_subtitle', true)}
                    </p>

                    <div >
                        <h1
                            className={className("welcome-title")}
                            dangerouslySetInnerHTML={{
                                __html: staticTranslate('t_home_header', true)
                            }} />
                    </div>

                    <Link
                        to="/pregnancy"
                        title="бременност"
                        className={className('welcome-button')}
                    >
                        {staticTranslate("t_home_button_learn_more", true)}

                    </Link>
                </div>
                <div className={className('images-container')}>
                    <div className={className('phone-wrapper')}>
                        <img src={phone} alt="phone" loading="lazy" />
                    </div>
                </div>
            </div>
            <div
                className={className('flower-container')}
            >
                <img src={flowerWith} alt="flowers" style={{
                    width: '100%',
                    height: 'auto'
                }}
                    loading="lazy"
                />
            </div>
        </div>
    )
}

export default Wellcome
