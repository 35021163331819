import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./DropDownPicker.css";

export default function DropDownPicker({
  placeholder = "Select an option",
  options = ["one", "two", "three"],
  defaultOption = options[0],
  onSelect = () => {},
}) {
  return (
    <Dropdown
      options={options}
      onChange={onSelect}
      value={defaultOption}
      placeholder={placeholder}
      controlClassName="control"
      placeholderClassName="DDplaceholder"
      menuClassName="DDmenuClassName"
      arrowClosed={<div className="arrow-closed" />}
      arrowOpen={<div className="arrow-open" />}
    />
  );
}
