const ENV_SERVER_URL = {
    test: 'https://test.feia-apps.com/api',
    stage: 'https://stage.feia-apps.com/api',
    prod: 'https://live.feia-apps.com/api',
};

const ENV_APP_URL = {
    test: {
        bg: 'https://feia-v2-test.web.app',
        en: 'https://feia-en-test.web.app',
    },
    stage: {
        bg: 'https://feia-v2-stage.web.app',
        en: 'https://feia-en-stage.web.app',
    },
    prod: {
        // bg: 'https://feia-apps.com',
        bg: 'https://feia.bg',
        en: 'https://gaia-apps.eu',
    }

};

export const SERVER_URL =
    process.env.NODE_ENV === 'development'
        ? ENV_SERVER_URL.test
        : ENV_SERVER_URL[process.env.REACT_APP_ENV];

/**
 * @typedef {Object} EnvAppUrl
 * @property {string} bg - The URL for the 'bg' environment.
 * @property {string} en - The URL for the 'en' environment.
 */

/**
 * @type {EnvAppUrl}
 */
export const APP_URL =
    process.env.NODE_ENV === 'development'
        ? ENV_APP_URL.test
        : ENV_APP_URL[process.env.REACT_APP_ENV];
