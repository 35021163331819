import { className } from '../../Utils';

import './BlogPostContent.css'

const BlogPostContent = ({ link, title, date, content }) => {
    const months = {
        1: 'януари',
        2: 'февруари',
        3: 'март',
        4: 'април',
        5: 'май',
        6: 'юни',
        7: 'юли',
        8: 'август',
        9: 'септемпри',
        10: 'октомври',
        11: 'ноември',
        12: 'декември'
    }
    
    //stript html tags
    let strippedString = content.replace(/(<([^>]+)>)/gi, "");

    return (
        <div >
            <a href={link}
                target="_blank"
                rel="noreferrer"
                className={className('blog-item-title-wrapper')}
                title={title}
            >
                <p className={className('blog-item-title')}
                    dangerouslySetInnerHTML={{
                        __html: title.length > 58
                            ? title.substring(0, 58) + '...'
                            : title
                    }} />
            </a>
            <p className={className('blog-item-date')}>
                {`
                   ${new Date(date).toLocaleDateString('bg-us', { day: 'numeric' })} 
                   ${months[new Date(date).toLocaleDateString('bg-us', { month: 'numeric' })]}
                   ${new Date(date).toLocaleDateString('bg-us', { year: 'numeric' })}
                 `}

            </p>
            <div className={className('blog-item-content')} dangerouslySetInnerHTML={{
                __html: strippedString.length > 200
                    ? strippedString.substring(0, 200) + '...'
                    : strippedString
            }} />
        </div>
    )
}

export default BlogPostContent
