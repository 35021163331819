import React from 'react'
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import PrivacyPolicyContent from './PrivacyPolicyContent/PrivacyPolicyContent';
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import { className } from '../../Utils';

import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    const { translations, language } = useSelector(state => state.home)

    return (
        <>
            <GlobalLoader showSpinner={translations[language] ? false : true} />
            <div className="App">
                <div id="main" className={className('privacy-policy-main')}>
                    <Menu mobile={isMobile} pageName='privacy-policy' />
                    <div id={"scrollContainer"} className="privacy-policy-main-container">
                        <PrivacyPolicyContent />
                        {language === 'bg' &&
                            <LastBlogArticles sections="section02" />
                        }
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy