export const ADD_MODAL = "CHAT::ADD_MODAL";
export const REMOVE_MODAL = "CHAT::CLOSE_MODAL";
export const REMOVE_ALL_MODALS = "CHAT::REMOVE_ALL_MODALS";
export const SHOW_COMMENTS = "CHAT::SHOW_COMMENTS";
export const SET_CATEGORIES = "CHAT::SET_CATEGORIES";
export const SET_SELECTED_CATEGORY = "CHAT::SET_SELECTED_CATEGORY";
export const SET_TREDS = "CHAT::SET_TREDS";
export const SET_TREDS_FOUND = "CHAT::SET_TREDS_FOUND";
export const SET_SELECTED_TRED = "CHAT::SET_SELECTED_TRED";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_SEARCH_STRING = "CHAT::SET_SEARCH_STRING";

const defaultState = {
  modalList: [],
  modal: false,
  isEdditing: false,
  showComments: false,
  categories: [],
  selectedCategory: null,
  treds: [],
  threadsFound: null,
  searchString: null,
  selectedTred: null,
  translations: {},
  language: "bg",
};

const chatReducer = (state = defaultState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_MODAL:
      return {
        ...state,
        modalList: [...state.modalList, payload],
      };
    case REMOVE_MODAL:
      return {
        ...state,
        modalList: state.modalList.filter((type) => type !== payload),
      };
    case REMOVE_ALL_MODALS:
      return {
        ...state,
        modalList: defaultState.modalList,
      };
    case SHOW_COMMENTS:
      return {
        ...state,
        showComments: payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case SET_TREDS:
      return {
        ...state,
        treds: payload,
      };
    case SET_SELECTED_TRED:
      return {
        ...state,
        selectedTred: payload,
      };
    case SET_TREDS_FOUND:
      return {
        ...state,
        threadsFound: payload,
      };

    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload,
      };
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
