
import { isMobile } from "react-device-detect";

import { useTranslations } from "../../lang/TranslationHooks";
import { useSelector } from "react-redux";

import { className } from '../../Utils';

import Menu from "../common/Menu/Menu";
import PageHeader from "./PageHeader/PageHeader"
import FutureMother from './futureMother/FutureMother'
import AccordionsSection from './accordionsSection/AccordionsSection'
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from "../common/footer/Footer";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import './Pregnancy.css'
import AddPriceContainer from "./AddPriceContainer/AddPriceContainer";
// import RatingContainer from "./RatingContainer/RatingContainer";
import PregnancyNewContent from "./PregnancyNewContent/PregnancyNewContent";
// import pregnancyBg from '../Pregnancy/assets/web.png'
import RatingWrapper from "../Home/RatingWrapper/RatingWrapper";

const Pregnancy = () => {
    let { staticTranslate } = useTranslations();
    const { translations, language } = useSelector(state => state.home)

    const rating = [
        {
            id: 1,
            text: staticTranslate('t_pregnancy_rating_1_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_1_textmobile'),
            author: staticTranslate('t_pregnancy_rating_1_author', true)
        },
        {
            id: 2,
            text: staticTranslate('t_pregnancy_rating_2_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_2_textmobile'),
            author: staticTranslate('t_pregnancy_rating_2_author', true)
        },
        {
            id: 3,
            text: staticTranslate('t_pregnancy_rating_3_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_3_textmobile'),
            author: staticTranslate('t_pregnancy_rating_3_author', true)
        },
        {
            id: 4,
            text: staticTranslate('t_pregnancy_rating_4_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_4_textmobile'),
            author: staticTranslate('t_pregnancy_rating_4_author', true)
        },
        {
            id: 5,
            text: staticTranslate('t_pregnancy_rating_5_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_5_textmobile'),
            author: staticTranslate('t_pregnancy_rating_5_author', true)
        },
        {
            id: 6,
            text: staticTranslate('t_pregnancy_rating_6_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_6_textmobile'),
            author: staticTranslate('t_pregnancy_rating_6_author', true)
        },
        {
            id: 7,
            text: staticTranslate('t_pregnancy_rating_7_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_7_textmobile'),
            author: staticTranslate('t_pregnancy_rating_7_author', true)
        },
        {
            id: 8,
            text: staticTranslate('t_pregnancy_rating_8_text', true),
            textMobile: staticTranslate('t_pregnancy_rating_8_textmobile'),
            author: staticTranslate('t_pregnancy_rating_8_author', true)
        }
    ]
    return (<>
        <GlobalLoader showSpinner={translations[language] ? false : true} />
        <div className="App">
            <div id="main" className={className('pregnancy-main')}>
                <Menu mobile={isMobile} pageName='pregnancy' />
                <div id={"scrollContainer"} className="pregnancy-main-container">
                    <PageHeader sections="section00" pageName='pregnancy' />
                    {/* <RatingContainer ratingData={rating} sections="section01" /> */}
                    <RatingWrapper ratingData={rating} sections="section01" />
                    <FutureMother />
                    <PregnancyNewContent />
                    <AccordionsSection sections="section04" />
                    <AddPriceContainer sections="section05" />
                    {language === 'bg' &&
                        <LastBlogArticles sections="section04" />
                    }
                    <Footer mobile={isMobile} />
                </div>
            </div>
        </div>
    </>

    )
}

export default Pregnancy
