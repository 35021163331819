export const initialCounter = () => {
    let perSecond = 100 / 24 / 60 / 60;
    let passedSeconds = (Date.now() - Date.parse('2021-11-01')) / 1000;
    let newUsers = Math.round(passedSeconds * perSecond);
    return 164477 + newUsers
}

export const easeOutExpo = (t, b, c, d) => {
    t /= d;
    t--;
    return c * (t * t * t * t * t + 1) + b;
};