import React from "react";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import App from "./App";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import Modal from "react-modal";
import "./index.css";
import { analyticsIds } from "./config/googleAnalyticsIds";

import ReactGA from 'react-ga4';

if (analyticsIds) {
  ReactGA.initialize(analyticsIds, { debug: true });

  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  });
}

const root = createRoot(document.getElementById("root"));
Modal.setAppElement("#root");
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
