import { useState } from 'react';
import { isMobile } from "react-device-detect";
import { useTranslations } from "../../../../lang/TranslationHooks";
import {  useSelector } from "react-redux";

import { className, scrollToSection } from '../../../../Utils';

import { bulgarianFlag, globalFlag } from '../../../../Images';
// import { germanyFlag } from '../../../../Images'

import './CountryPicker.css';
import { APP_URL } from '../../../../config/serverUrl';
// import { changeLanguge } from '../../../../store/actions/homeActions';
// import { Link } from 'react-router-dom';

const CountryPicker = () => {
    let { staticTranslate } = useTranslations();
    // let dispatch = useDispatch();

    const { language } = useSelector((state) => state.home);

    const [showCountries, setShowCountries] = useState(false);

    const countryDataByLanguage = {
        en: {
            country: staticTranslate('t_home_country_picker_global', true),
            country_flag: globalFlag,
            lang: 'en',
            websiteUrl: APP_URL.en,
        },
        bg: {
            country: staticTranslate('t_home_country_picker_bulgaria', true),
            country_flag: bulgarianFlag,
            lang: 'bg',
            websiteUrl: APP_URL.bg,
        },
        // de: {
        //     country: staticTranslate('t_home_country_picker_germany', true),
        //     country_flag: germanyFlag,
        //     lang: 'de'
        // }
    };

    const selectedCountryData = countryDataByLanguage[language] || countryDataByLanguage.bg; // Fallback to Bulgarian if language is not found
    const currenData = language === 'bg' ? countryDataByLanguage.en :countryDataByLanguage.bg
    
    return (
        <div
            className={className('country-picker-wrapper')}
            onMouseEnter={() => !isMobile && setShowCountries(true)}
            onClick={() => isMobile && setShowCountries(x => !x)}
        >
            <div className={className('country-picker-flag-container')}>
                <img src={selectedCountryData.country_flag} alt="flag" />
            </div>
            <p className={className('country-picker-title-selected')}>
                {selectedCountryData.country}
            </p>

            {showCountries && (
                <div
                    className={className('all-country-picker-wrapper')}
                    onMouseLeave={() => !isMobile && setShowCountries(false)}
                >
                            <a
                                onClick={(e) => {
                                    e.stopPropagation(); // Stop propagation to parent div
                                    setShowCountries(false);
                                    scrollToSection('section00');
                                    // dispatch(changeLanguge(item?.lang));
                                }}
                                href={currenData?.websiteUrl}
                                target="_blank"
                                className={className('country-picker-single-container') + ' ' + className(`country-picker-single-container-${currenData.country}`)}
                                title='home'
                                rel="noreferrer"
                            >
                                <div className={className('country-picker-flag-container')}>
                                    <img src={currenData.country_flag} alt="flag" loading='lazy' />
                                </div>
                                <p className={className('country-picker-title')}>
                                    {currenData.country}
                                </p>
                            </a>
                </div>
            )}
        </div>
    );
};

export default CountryPicker;
