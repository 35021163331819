import React, { useRef, useEffect } from "react";
import "./RulesPopup.css";
import { isMobile } from "react-device-detect";
import { useTranslations } from "../../../../lang/TranslationHooks";

export default function RulesPopup({ toggleModal }) {
  const rulesRef = useRef(null);
  let { staticTranslate, translateHtml } = useTranslations();

  useEffect(() => {
    if (rulesRef.current) {
      rulesRef.current.innerHTML = translateHtml("t_community_rules");
    }
  }, [rulesRef, translateHtml]);

  return (
    <div className={`userMenu${isMobile ? " -mobile" : ""}`}>
      <div className="controlBar">
        <div className="closeIcon" onClick={toggleModal} />
        <div className="gap" />
      </div>
      <div className="rulesContainer">
        <span className="rulesTitle">
          {staticTranslate("t_community_rules_title", true)}
        </span>
        <span className="rulesBody" ref={rulesRef} />
      </div>
    </div>
  );
}  
