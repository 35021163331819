import React from 'react'
import { fairy_gif } from '../../Images'
import { className } from '../../Utils'

import './GlobalLoader.css'
const GlobalLoader = ({ showSpinner }) => {
    return (
        showSpinner &&
        <div className={className('global-loader-wrapper')}>
            <span className={className('loader')}></span>

            <div className={className('global-loader-image-container')}>
                <img src={fairy_gif} alt="fairy" loading='lazy' />
            </div>
        </div>
    )
}

export default GlobalLoader