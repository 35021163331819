import { className } from '../../../../Utils';

import checkedNew from '../../assets/checkedNew.svg'
import iconX from '../../assets/iconX.svg'
import './AccordionContentDesktop.css'

const AccordionContentDesktop = ({ content, itemTitle, freeFunctionality }) => {
    return (
        <article className={className('accordion-container')}>
            <div className={className('accordion-content')}>
                <div className={className('accordion-content-wrapper')}>
                    <h5>
                        {itemTitle}
                    </h5>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    ></p>
                </div>
                <div
                    className={className('accordion-icon-container')}>
                    {freeFunctionality
                        ? <div className={className('accordion-icon-wrapper')}>
                            <img
                                src={checkedNew}
                                className={className('accordion-icon-checked')}
                                alt="checked"
                                loading='lazy'
                            />
                        </div>
                        : <div className={className('accordion-icon-wrapper')}>
                            <img
                                src={iconX}
                                className={className('accordion-icon-freeIcon')}
                                alt="free icon"
                                loading='lazy'
                            />
                        </div>
                    }
                    <div className={className('crown-wrapper')}>
                        <img
                            src={checkedNew}
                            className={className('accordion-icon-checked')}
                            alt="checked"
                            loading='lazy'
                        />
                    </div>
                </div>
            </div>
        </article>
    )
}

export default AccordionContentDesktop
