import { CHANGE_LANGUAGE, GET_ALL_SPONSORS, SET_HOME_KEYS } from '../reducers/homeReducer'

export const setHomeTranslations = (translations) => {
    return {
        type: SET_HOME_KEYS,
        payload: translations,
    };
};

export const getAllSponsors = (sponsors) => {
    return {
        type: GET_ALL_SPONSORS,
        payload: sponsors
    }
}

export const changeLanguge = (lang) => {
    return {
        type: CHANGE_LANGUAGE,
        payload: lang
    }
}