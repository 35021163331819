
import { className } from "../../../Utils.js";
import { useTranslations } from "../../../lang/TranslationHooks";

import blogImage from '../assets/webp/laptop2.webp'

import "./Blog.css";

const Blog = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('home-section') + ' ' + className('home-blogs')} id={'section03'}
        >
            <div className={className('home-section-content-container')}>
                {/* <p className={className('comming-soon-title')}>coming soon</p> */}
                <h2 className={className('home-section-title')}>
                    {staticTranslate('t_home_feia', true)}<span> {staticTranslate('t_home_blog_title', true)} </span>
                </h2>
                <h3 className={className('home-section-subtitle')}>
                    {staticTranslate('t_home_subtitle', true)}
                </h3>

                <p className={className('home-section-paragraph')}>
                    {staticTranslate('t_home_blog_description', true)}
                </p>
                <a
                    href="https://feia.bg/shop/blog-site/"
                    target='_blank'
                    rel="noreferrer"
                    title={staticTranslate('t_home_blog_button_name', true)}
                >
                    <p className={className('home-section-button')}>
                        {staticTranslate('t_home_blog_button_name', true)}
                    </p>
                </a>
            </div>

            <div className={className('home-section-image-container')}>
                <img src={blogImage} className={className('blog-image')} alt="Feia Blog" loading="lazy" />
            </div>
        </div>
    );
}

export default Blog