import React, { useEffect } from "react";
import { isMobile } from 'react-device-detect';

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { allSposors } from "../../../databaseUtil";

import { className } from "../../../Utils";
import Slider from "react-slick";

import "./Sponsors.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sponsors = () => {
  const dispatch = useDispatch()
  const { sponsors, language } = useSelector(state => state.home) || []

  useEffect(() => {
    dispatch(allSposors(language))
  }, [language, dispatch])


  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: isMobile ? true : sponsors?.length > 3 ? true : false,
    speed: 100,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };


  return (
    sponsors?.length > 0 &&
    <div className={className('sponsors-container')} id='section05' >

      <div className={className('sponsors-images-container')}

      >
        <Slider {...settings} className="slider-container" >
          {sponsors?.map((ad) => (
            <div key={ad.id} className={className('slick-slider-wrapper')} >
              <a
                href={ad.web_logo_url}
                className={className('logo-image')}
                target="_blank"
                rel="noreferrer"
                title={ad.partners_name}
              >
                <img
                  src={ad.imageUrl}
                  className={className('sponsors-logo')}
                  alt={ad.partners_name}
                  loading="lazy"
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Sponsors;
