import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const SET_HOME_KEYS = 'SET_HOME_KEYS'
export const GET_ALL_SPONSORS = 'GET_ALL_SPONSORS'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

const persistConfig = {
    key: 'home',
    storage,
    blacklist: ['language'],
};

const defaultState = {
    translations: {},
    sponsors: [],
    language: process.env.REACT_APP_LANGUAGE || 'bg'
};

const homeReducer = (state = defaultState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case SET_HOME_KEYS:
            return {
                ...state,
                translations: payload,
            };
        case GET_ALL_SPONSORS:
            return {
                ...state,
                sponsors: payload
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: payload
            };
        default:
            return state;
    }
};

export default persistReducer(persistConfig, homeReducer);
