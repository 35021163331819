import { useLocation } from 'react-router-dom';

import { className } from '../../Utils';

import './BlogCategory.css'

const BlogCategory = () => {
    let location = useLocation();

    return (
        <div>
            <a href={location.pathname === '/pregnancy'
                ? 'https://feia.bg/shop/blog-site/category/bremennost/'
                : 'https://feia.bg/shop/blog-site/category/roditeli/'}
                target="_blank"
                rel="noreferrer"
                className={className('blog-category-title')}
            >
                <p className={className('blog-item-p')}>
                    {location.pathname === '/pregnancy'
                        ? 'БРЕМЕННОСТ'
                        : 'РОДИТЕЛИ'}
                </p>
            </a>
        </div>
    )
}

export default BlogCategory
