import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import AboutUsHeader from "./AboutUsHeader/AboutUsHeader";
import AboutUsContent from "./AboutUsContent/AboutUsContent";
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import { className } from '../../Utils';

import './AboutUs.css'

const AboutUs = () => {
  const { translations, language } = useSelector(state => state.home)

  return (
    <>
      <GlobalLoader showSpinner={translations[language] ? false : true} />
      <div className="App">
        <div id="main" className={className('aboutUs-main')}>
          <Menu mobile={isMobile} pageName='aboutUs' />
          <div id={"scrollContainer"} className="aboutUs-main-container">
            <AboutUsHeader />
            <AboutUsContent />
            {language === 'bg' &&
              <LastBlogArticles sections="section04" />
            }
            <Footer />
          </div>
        </div>
      </div>
    </>

  )
}

export default AboutUs