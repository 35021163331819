import { isMobile } from "react-device-detect";

import { className } from "../../../Utils.js";
import { useTranslations } from "../../../lang/TranslationHooks";

import shopImage from '../assets/webp/laptop1.webp'

import "./Shop.css";

const Shop = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('home-section')} id='section04'
        >
            <div className={className('home-section-image-container')}>
                <img src={shopImage} className={className('feia-shop-image')} alt="Feia Shop" loading="lazy" />
            </div>
            <div className={className('home-section-content-container')}>
                <h2 className={className('home-section-title')}>
                    {staticTranslate('t_home_feia', true)} <span> {staticTranslate('t_home_shop_title', true)} </span>
                </h2>
                <h3 className={className('home-section-subtitle')}>
                    {staticTranslate('t_home_shop_subtitle', true)}
                </h3>
                <div
                    className={className('home-section-paragraph')}
                    dangerouslySetInnerHTML={{
                        __html: isMobile
                            ? staticTranslate('t_home_shop_description_mobile')
                            : staticTranslate('t_home_shop_description')
                    }} />
                <div className={className('home-section-paragraph')}
                    dangerouslySetInnerHTML={{
                        __html: staticTranslate('t_home_shop_second_description')
                    }}
                />
                <a
                    href="https://feia.bg/shop/"
                    target="_blank"
                    rel="noreferrer"
                    title={staticTranslate('t_home_shop_button_name', true)}
                >
                    <p className={className('home-section-button')}>
                        {staticTranslate('t_home_shop_button_name', true)}
                    </p>
                </a>
            </div>
        </div>
    );
}

export default Shop