import React from 'react'
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import FAQHeader from './FAQHeader/FAQHeader';
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import { className } from '../../Utils';
import { isMobile } from "react-device-detect";

import './FAQ.css'

const FAQ = () => {
  const { translations, language } = useSelector(state => state.home);
  return (
    <>
      <GlobalLoader showSpinner={translations[language] ? false : true} />
      <div className="App">
        <div id="main" className={className('faq-main')}>
          <Menu mobile={isMobile} pageName='faq' />
          <div id={"scrollContainer"} className="faq-main-container">
            <FAQHeader />
            {language === 'bg' &&
              <LastBlogArticles sections="section02" />
            }
            <Footer />
          </div>
        </div>
      </div>
    </>

  )
}

export default FAQ