import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import config from '../config/firebaseConfig';

const app = initializeApp(config.firebase);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const getUserToken = async () => {
  const userToken = await auth.currentUser?.getIdToken();
  return userToken;
}

export default app;
