import {
  SHOW_COMMENTS,
  SET_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_TREDS,
  SET_SELECTED_TRED,
  SET_TRANSLATIONS,
  SET_TREDS_FOUND,
  SET_SEARCH_STRING,
  ADD_MODAL,
  REMOVE_MODAL,
  REMOVE_ALL_MODALS,
} from "../reducers/chatReducer";

export const addModal = (type) => {
  return {
    type: ADD_MODAL,
    payload: type,
  };
};
export const removeModal = (type) => {
  return {
    type: REMOVE_MODAL,
    payload: type,
  };
};
export const removeAllModals = () => {
  return {
    type: REMOVE_ALL_MODALS,
  };
};
export const ShowComments = (isOpen) => {
  return {
    type: SHOW_COMMENTS,
    payload: isOpen,
  };
};
export const SetCategories = (categories) => {
  return {
    type: SET_CATEGORIES,
    payload: categories,
  };
};

export const SetSelectedCategory = (category) => {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: category,
  };
};
export const SetTreds = (treds) => {
  return {
    type: SET_TREDS,
    payload: treds,
  };
};
export const SetSelectedTred = (tred) => {
  return {
    type: SET_SELECTED_TRED,
    payload: tred,
  };
};
export const SetTredsFound = (treds) => {
  return {
    type: SET_TREDS_FOUND,
    payload: treds,
  };
};
export const SetTranslations = (tranclations) => {
  return {
    type: SET_TRANSLATIONS,
    payload: {},
  };
};

export const SetSearchString = (str) => {
  return {
    type: SET_SEARCH_STRING,
    payload: str,
  };
};
