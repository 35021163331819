import React from "react";
import Modal from "react-modal";
import "./Modal.css";

export default function ModalDialog({ isOpen, toggleModal, content }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="modal"
    >
      {content}
    </Modal>
  );
}
