export const GET_ALL_BLOGS = 'GET_ALL_BLOGS';
export const FETCH_BLOGS_FAILED = 'FETCH_BLOGS_FAILED'

const defaultState = {
    blogs: [],
    blogsErrors: null,
};

const blogReducer = (state = defaultState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BLOGS:
            return {
                ...state,
                blogs: payload,
                blogsErrors: null,
            };
        case FETCH_BLOGS_FAILED:
            return {
                ...state,
                blogsErrors: payload
            }

        default:
            return state;
    }
};

export default blogReducer;