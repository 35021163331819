import { SET_USER, LOG_OUT, SET_NOTIF, SHOW_USER_MENU, DOWNLOAD_APP } from "../reducers/userReducer";

export const setUser = (userObj) => {
  return {
    type: SET_USER,
    payload: userObj,
  };
};

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};
export const SetNotificationsList = (data) => {
  return {
    type: SET_NOTIF,
    payload: data,
  };
};

export const showUserMenu = (visible) => {
  return {
    type: SHOW_USER_MENU,
    payload: visible
  }
}

export const downloadAppCounter = (number) => {
  return {
    type: DOWNLOAD_APP,
    payload: number
  }
}
