import { CountUp } from 'use-count-up'

import { className } from '../../../Utils'
import { easeOutExpo } from './PregnancyCounterHelpers'
import { useSelector } from "react-redux";

import './PregnancyCounter.css'

const PregnancyCounter = ({ title }) => {
    const { registeredUsers } = useSelector(state => state.user);
    return (
        <div className={className('pregnancy-header-counter')}>
            <h4 className={className('pregnancy-header-counter-text')}>
                <CountUp
                    isCounting={true}
                    end={isNaN(registeredUsers) ? 223087 : 223087 + registeredUsers}
                    duration={3}
                    thousandsSeparator=" "
                    easing={easeOutExpo}
                />
            </h4>
            <h4 className={className('pregnancy-header-counter-install')}>
                {title}
            </h4>
        </div>
    )
}

export default PregnancyCounter
