import React from 'react'
import { clouds } from '../../../Images'
import { className } from '../../../Utils'
import { aboutUs_artwork } from '../../../Images'
import { useTranslations } from '../../../lang/TranslationHooks'

import './ContactsHeader.css'
const ContactsHeader = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('contacts-header')} id="section00" >
            <div className={className('contacts-artwork')}>
                <img src={aboutUs_artwork} alt="artwork" loading='lazy' />
            </div>
            <h1
                data-text={staticTranslate('t_contacts_header_title', true)}
                className={className('contacts-header-title')}
            >
                {staticTranslate('t_contacts_header_title', true)}
            </h1>

            <div className={className('contacts-header-image-container')}>
                <img src={clouds} alt="cloud" loading='lazy' />
            </div>
        </div>

    )
}

export default ContactsHeader