import { Routes, Route } from "react-router-dom";

import Home from "./components/Home/Home";
import Pregnancy from "./components/Pregnancy/Pregnancy";
import FAQ from "./components/FAQ/FAQ";
import Contacts from "./components/Contacts/Contacts";
import AboutUs from "./components/AboutUs/AboutUs";
import AndroidPayment from "./components/AndroidPayment/AndroidPayment";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import IOSPayment from "./components/IOSPayment/IOSPayment";
import Terms from "./components/Terms/Terms";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllRegisterdUsers, getAllTranslations } from "./databaseUtil";

const App = () => {

  let dispatch = useDispatch();
  const { language } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getAllTranslations(language))
    dispatch(getAllRegisterdUsers());
  }, [dispatch, language]);
  return (
    <Routes>

      <Route exact path="/" element={language === 'bg' ? <Home /> : <Pregnancy />} />
      {language === 'bg' && <Route exact path="/pregnancy" element={<Pregnancy />} />}

      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/faq" element={<FAQ />} />
      <Route exact path="/aboutUs" element={<AboutUs />} />
      <Route exact path="/contacts" element={<Contacts />} />
      {language === 'bg' && <Route exact path="/android-payment" element={<AndroidPayment />} />}
      {language === 'bg' && <Route exact path="/ios-payment" element={<IOSPayment />} />}
      <Route exact path="/terms" element={<Terms />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
