import React from 'react'
import { className } from '../../../Utils';

const VideoRendering = ({ videoUrl, cssStyles, vidRef }) => {

    return (
        <video
            src={videoUrl}
            autoPlay
            ref={vidRef}
            className={className(cssStyles)}
            loop
            controls={false}
            playsInline
            webkit-playsinline="true"
            muted
        />
    );
}

export default VideoRendering