import React from 'react';

import './CarouselMobileDots.css'

const CarouselMobileDots = ({ data, slideIndex }) => {
    return <div className="rating-dots-carousel-mobile">
        {data.map((item, index) => <span
            key={index}
            className={slideIndex === index
                ? 'dot-carousel active-dot-carousel span-dots-carousel-mobile'
                : 'dot-carousel span-dots-carousel-mobile'}
        />
        )}
    </div>
};

export default CarouselMobileDots;
