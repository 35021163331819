import { useEffect } from 'react';
import { useTranslations } from "../../lang/TranslationHooks";

import SocialMediaIcons from './SocialMediaIcons.js';
import { className } from '../../Utils';

import ImageSection from './ImageSection.js';
import BlogPostContent from './BlogPostContent.js';
import BlogCategory from './BlogCategory.js';

import './LastBlogArticles.css'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getLastBlogs } from '../../store/api/blog';
import ErrorContainer from '../common/ErrorContainer/ErrorContainer';

// const pregnancyUrl = 'https://feia.bg/shop/blog-site/wp-json/wp/v2/posts?per_page=3';
// const parentsUrl = 'https://feia.bg/shop/blog-site/wp-json/wp/v2/posts?per_page=3&categories=19';

const LastBlogArticles = ({ sections }) => {
    const dispatch = useDispatch()
    const { blogs, blogsErrors } = useSelector((state) => state.blog)
    let { staticTranslate } = useTranslations();

    useEffect(() => {
        dispatch(getLastBlogs())
    }, [dispatch])

    return (
        <div id={sections.key} className={className('last-blogs-post-wrapper')} >
            <div className={className('section-container')}>
                <div className={className('section-container-blog-articles')}>
                    <h2>
                        {staticTranslate('t_pregnancy_blog_title', true)} <span> {staticTranslate("t_pregnancy_blog_title_with_color", true)}</span>
                    </h2>
                </div>
                <div className={className('section-container-subTitle')}>
                    <h3>
                    {staticTranslate('t_pregnancy_blog_subtitle', true)}

                    </h3>
                </div>
                {blogsErrors
                    ? <div className={className('last-blogs-error')} >
                        <ErrorContainer />
                    </div>
                    : <div className={className('blog-container-content')}>
                        {
                            blogs?.length > 0
                            && blogs?.map((item) => (
                                <div key={item.id} className={className('blog-container-item')}>
                                    <ImageSection {...item} />
                                    <BlogCategory />
                                    <BlogPostContent
                                        link={item.link}
                                        title={item.title.rendered}
                                        date={item.date}
                                        content={item.content.rendered}
                                    />
                                    <div className={className('blog-icons-container')}>
                                        <SocialMediaIcons url={item.link} title={item.title.rendered} />
                                    </div>
                                </div>
                            ))}
                    </div>

                }
            </div>
        </div>
    )
}

export default LastBlogArticles;
