const firebaseConfig = {
  test: {
    apiKey: "AIzaSyBRJTnQqpMMF0P--t12OQy05BpV57F9nrA",
    authDomain: "feia-v2-test.firebaseapp.com",
    projectId: "feia-v2-test",
    storageBucket: "feia-v2-test.appspot.com",
    messagingSenderId: "944247332625",
    appId: "1:944247332625:web:d85e80b42a5107fcfcf6b0",
    measurementId: "G-N6ZX3DYRM5",
  },
  stage: {
    apiKey: "AIzaSyBDyYtBvRf6_l9uDh7PcOL0cjUO3HuJbS8",
    authDomain: "feia-v2-stage.firebaseapp.com",
    projectId: "feia-v2-stage",
    storageBucket: "feia-v2-stage.appspot.com",
    messagingSenderId: "179828706615",
    appId: "1:179828706615:web:745dcde13c87aed52e5eb2",
    measurementId: "G-J51Z9NERD4",
  },
  prod: {
    apiKey: "AIzaSyBvcdstuWSPJM_BWVyTopGZYGQeg9KTSxc",
    authDomain: "feia-v2.firebaseapp.com",
    projectId: "feia-v2",
    storageBucket: "feia-v2.appspot.com",
    messagingSenderId: "369541159444",
    appId: "1:369541159444:web:187410cbf313290550a501",
    measurementId: "G-MNHDLJQFB3",
  },
};

const config = {
  firebase: firebaseConfig[process.env.REACT_APP_ENV] || firebaseConfig.test,
};

export default config;
