import React from 'react'
import { className } from '../../../Utils';
import SingleContainer from './SingleContainer';
import { useTranslations } from "../../../lang/TranslationHooks";

import screen1 from '../assets/ios-screen1.png'
import screen2 from '../assets/ios-screen2.png'
import screen3 from '../assets/ios-screen3.png'
import screen4 from '../assets/ios-screen4.png'
import screen5 from '../assets/ios-screen5.png'

import './IOSPaymentContent.css'

const IOSPaymentContent = () => {
    let { staticTranslate } = useTranslations();

    const content = [
        {
            id: 1,
            title: staticTranslate('t_ios_payment_content_1_title', true),
            description: staticTranslate('t_ios_payment_content_1_description'),
            imgPath: screen1
        },
        {
            id: 2,
            title: staticTranslate('t_ios_payment_content_2_title', true),
            description: staticTranslate('t_ios_payment_content_2_description'),
            imgPath: screen2
        },
        {
            id: 3,
            title: staticTranslate('t_ios_payment_content_3_title', true),
            description: staticTranslate('t_ios_payment_content_3_description'),
            imgPath: screen3
        },
        {
            id: 4,
            title: staticTranslate('t_ios_payment_content_4_title', true),
            description: staticTranslate('t_ios_payment_content_4_description'),
            imgPath: screen4
        },
        {
            id: 5,
            title: staticTranslate('t_ios_payment_content_5_title', true),
            description: staticTranslate('t_ios_payment_content_5_description'),
            imgPath: screen5
        }
    ]

    return (
        <div className={className('ios-payment-content')} id="section01" >
            {content.map((item) => <SingleContainer key={item.id} {...item} />)}
        </div>)
}

export default IOSPaymentContent