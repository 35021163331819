import { SET_USER } from "./userReducer";

export const SET_ERROR = "SET_ERROR";

const defaultState = {
  error: null,
};

const errorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default errorReducer;
