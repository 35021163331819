import axios from "axios";
import { SERVER_URL } from "../../config/serverUrl";
import { FETCH_FAQS_FAILED, GET_ALL_FAQS_BY_LANG } from "../reducers/faqReducer";
import bgFaq from '../../components/FAQ/assets/bgFaq.json'

export function getFaqByLang(lang) {
    return async (dispatch) => {
        if (lang === 'bg') {
            dispatch({ type: GET_ALL_FAQS_BY_LANG, payload: bgFaq })
        } else {
            try {
                const response = await axios.get(`${SERVER_URL}/admin/getFaqsByLang/${lang}`);
                dispatch({ type: GET_ALL_FAQS_BY_LANG, payload: response.data })
            } catch (err) {
                dispatch({ type: FETCH_FAQS_FAILED, payload: err.message })
            }
        }
    }
}
