import React from 'react'
import CarouselClouds from '../../carousel/CarouselClouds/CarouselClouds'
import Rating from '../../rating/Rating'
import { className } from '../../../Utils'

import "./RatingWrapper.css"

const RatingWrapper = ({ ratingData, sections }) => {
    return (
        <div id={sections}
            className={className('rating-component')}
        >
            {ratingData && <Rating ratingData={ratingData} />}
            <CarouselClouds />
        </div>
    )
}

export default RatingWrapper