import React from 'react'
import { className } from "../../Utils";

import './InstallNow.css'

const InstallNow = ({ text, animate = true, isPriceSection, intClasName }) => {
    return (
        <div
            className={className('header-install-now-container') + ' ' + className(animate && 'animate-install-now') + ' ' + className(intClasName)}>
            <p data-text={text}
                className={className(isPriceSection ? 'header-install-now-title-price' : 'header-install-now-title')}
            >
                {text}
            </p>
        </div>
    )
}

export default InstallNow