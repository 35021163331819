import { className } from "../../../Utils";

import Accordion from './accordion/Accordion';
import AppCarousel from '../../carousel/AppsCarousel'

import { useTranslations } from "../../../lang/TranslationHooks";

import free_functionality from '../../Pregnancy/assets/free.mp4'
import feiaPlus from '../../Pregnancy/assets/feiaplus.mp4'
import miniAps from '../../Pregnancy/assets/miniapps.mp4'

import './AccordionsSection.css'
import { useSelector } from "react-redux";

const AccordionSection = ({ sections }) => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)

    const accordionData = {
        functioanality: [
            {
                title: staticTranslate('t_pregnancy_accordion_functioanality_title', true),
                videoUrl: free_functionality,
                freeFunctionality: true,
                data: [
                    {
                        id: 2,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_1_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_1_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_1_content', true),
                    },
                    {
                        id: 3,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_2_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_2_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_2_content', true),
                    },
                    {
                        id: 4,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_3_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_3_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_3_content', true),
                    },
                    {
                        id: 5,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_4_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_4_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_4_contentmobile'),
                    },
                    {
                        id: 6,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_5_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_5_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_5_content', true),
                    },
                    {
                        id: 7,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_6_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_6_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_6_content', true),

                    },
                    {
                        id: 8,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_7_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_7_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_7_content', true),
                    }, {
                        id: 9,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_8_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_8_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_8_content'),
                    },
                    {
                        id: 10,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_9_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_9_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_9_content', true),
                    },
                    language === 'bg' &&
                    {
                        id: 11,
                        title: staticTranslate('t_pregnancy_accordion_functioanality_data_10_title', true),
                        content: staticTranslate('t_pregnancy_accordion_functioanality_data_10_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_functioanality_data_10_content', true),
                    }
                ].filter(Boolean)
            }

        ],
        sectionImportant: [
            {
                title: staticTranslate('t_pregnancy_accordion_important_title', true),
                videoUrl: feiaPlus,
                data: [
                    {
                        id: 1,
                        title: staticTranslate('t_pregnancy_accordion_important_data_1_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_1_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_1_contentmobile', true),
                    },
                    language === 'bg' &&
                    {
                        id: 2,
                        title: staticTranslate('t_pregnancy_accordion_important_data_2_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_2_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_2_contentmobile', true),
                    },
                    language === 'bg' &&
                    {
                        id: 3,
                        title: staticTranslate('t_pregnancy_accordion_important_data_3_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_3_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_3_contentmobile', true),
                    },
                    {
                        id: 4,
                        title: staticTranslate('t_pregnancy_accordion_important_data_4_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_4_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_4_content', true)
                    },
                    {
                        id: 5,
                        title: staticTranslate('t_pregnancy_accordion_important_data_5_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_5_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_5_content', true),
                    },
                    {
                        id: 6,
                        title: staticTranslate('t_pregnancy_accordion_important_data_6_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_6_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_6_content', true),
                    },
                    {
                        id: 7,
                        title: staticTranslate('t_pregnancy_accordion_important_data_7_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_7_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_7_content', true),
                    },
                    language === 'bg' &&
                    {
                        id: 8,
                        title: staticTranslate('t_pregnancy_accordion_important_data_8_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_8_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_8_content', true),
                    },
                    language === 'bg' &&
                    {
                        id: 9,
                        title: staticTranslate('t_pregnancy_accordion_important_data_9_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_9_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_9_contentmobile')
                    },
                    {
                        id: 10,
                        title: staticTranslate('t_pregnancy_accordion_important_data_10_title', true),
                        content: staticTranslate('t_pregnancy_accordion_important_data_10_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_important_data_10_content', true)
                    }

                ].filter(Boolean)

            }
        ],
        miniApps: [
            {
                title: staticTranslate('t_pregnancy_accordion_miniaps_title', true),
                videoUrl: miniAps,
                data: [
                    {
                        id: 1,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_1_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_1_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_1_content', true)
                    },
                    {
                        id: 2,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_2_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_2_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_2_contentmobile', true)

                    },
                    {
                        id: 3,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_3_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_3_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_3_contentmobile', true)
                    },
                    {
                        id: 4,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_4_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_4_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_4_contentmobile', true)

                    },
                    {
                        id: 5,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_5_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_5_content'),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_5_contentmobile', true)
                    },
                    {
                        id: 6,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_6_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_6_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_6_content', true)
                    },
                    {
                        id: 7,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_7_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_7_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_7_content', true)
                    },
                    {
                        id: 8,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_8_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_8_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_8_contentmobile', true)
                    },
                    {
                        id: 9,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_9_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_9_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_9_contentmobile', true)
                    },
                    {
                        id: 10,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_10_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_10_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_10_contentmobile', true)
                    },
                    {
                        id: 11,
                        title: staticTranslate('t_pregnancy_accordion_miniaps_data_11_title', true),
                        content: staticTranslate('t_pregnancy_accordion_miniaps_data_11_content', true),
                        contentMobile: staticTranslate('t_pregnancy_accordion_miniaps_data_11_contentmobile', true)
                    },
                ].filter(Boolean)
            }
        ],
    }

    return (
        <div
            id={sections}
            className={className('accordion-section-container')}
        >
            <h2 className={className('accordions-title')}>{staticTranslate('t_pregnancy_accordion_title', true)} </h2>
            <h3 className={className('accordions-content')}>{staticTranslate('t_pregnancy_accordion_subtitle', true)}</h3>


            <AppCarousel />

            <Accordion {...accordionData.functioanality[0]} />
            <Accordion {...accordionData.sectionImportant[0]} />
            <Accordion {...accordionData.miniApps[0]} />

            <p className={className("accordion-section-container-info-text")}>{staticTranslate('t_pregnancy_accordion_container_text_info', true)}</p>
        </div>
    )
}

export default AccordionSection