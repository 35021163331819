import React from 'react'
import { className } from '../../../Utils'

import { fairyWithQuestions } from '../../../Images'

import './ErrorContainer.css'

const ErrorContainer = () => {
    return (
        <div className={className('error-wrapper')}>
            <p
                data-text="400"
                className={className('error-wrapper-title')}
            >
                400
            </p>
            <div className={className('error-fairy-container')}>
                <img src={fairyWithQuestions} alt="fairy with questions" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>

            <p className={className('error-description')}>Грешко в съръва моля опитайте отново. Грешко в съръва моля опитайте отново.Грешко в съръва моля опитайте отново</p>
        </div>
    )
}

export default ErrorContainer