import { isMobile } from "react-device-detect";
import ErrorContent from "./ErrorContent/ErrorContent";

import { className } from "../../Utils";
import Menu from "../common/Menu/Menu";

import './ErrorPage.css'

const ErrorPage = () => {
    return (
        <div className="App">
            <div id="main" className={className('main')}>
                <Menu mobile={isMobile} pageName='error' />
                <div id={"scrollContainer"} className="error-container">
                    <ErrorContent />
                </div>
            </div>
        </div>
    )
}

export default ErrorPage