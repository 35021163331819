import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { className } from '../../../Utils'
import { useTranslations } from "../../../lang/TranslationHooks";

import { useSelector } from 'react-redux';

import { newContentImages } from '../PregnancyConstants';

import VideoRendering from '../../common/VideoRendering/VideoRendering';

import comparisonPhone from '../../../assets/newContent/comparison-phone.png'

import './PregnancyNewContent.css'

const PregnancyNewContent = () => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)
    const vidRef = useRef(null);

    const content = [
        {
            id: 1,
            title: staticTranslate('t_pregnancy_new_content_1_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_1_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_1_mobile_content'),
            imagePath: {
                bg: newContentImages.familyBg,
                en: newContentImages.familyEn,
                de: newContentImages.familyDe,
            },

        },
        {
            id: 2,
            title: staticTranslate('t_pregnancy_new_content_2_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_2_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_2_mobile_content'),
            imagePath: {
                bg: '',
                en: '',
                de: ''
            },
            phoneImage: comparisonPhone,
            videoUrl: {
                bg: newContentImages.newComparisionBg,
                en: newContentImages.comparisionEn,
                de: newContentImages.comparisionDE
            }
        },
        {
            id: 3,
            title: staticTranslate('t_pregnancy_new_content_3_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_3_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_3_mobile_content'),
            imagePath: {
                bg: newContentImages.calendarBg,
                en: newContentImages.calendarEN,
                de: newContentImages.calendarDE
            },
        },
        {
            id: 4,
            title: staticTranslate('t_pregnancy_new_content_4_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_4_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_4_mobile_content'),
            imagePath: {
                bg: newContentImages.notificationsBg,
                en: newContentImages.notificationsEN,
                de: newContentImages.notificationsDE
            },
        },
        {
            id: 5,
            title: staticTranslate('t_pregnancy_new_content_5_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_5_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_5_mobile_content'),
            imagePath: {
                bg: newContentImages.searchBg,
                en: newContentImages.searchEN,
                de: newContentImages.searchDE
            },
        },
        {
            id: 6,
            title: staticTranslate('t_pregnancy_new_content_6_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_6_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_6_mobile_content'),
            imagePath: {
                bg: newContentImages.bellyBg,
                en: newContentImages.bellyEn,
                de: newContentImages.bellyDE
            },
        },
        {
            id: 7,
            title: staticTranslate('t_pregnancy_new_content_7_title', true),
            desktop_content: staticTranslate('t_pregnancy_new_content_7_desktop_content'),
            mobile_content: staticTranslate('t_pregnancy_new_content_7_mobile_content'),
            imagePath: {
                bg: newContentImages.IBecomeAMotherBg,
                en: newContentImages.IBecomeAMotherEN,
                de: newContentImages.IBecomeAMotherDE
            },
        },
    ].slice(0, 3)

    return (
        <div className={className('pregnancy-new-content-wrapper')} id="section03">
            <div className={className('pregnancy-new-content-container')}>
                {content.map((item, index) => (
                    <div className={className('pregnancy-new-single-container')} key={index}>
                        <div className={className('pregnancy-new-single-container-content')}>
                            <h3 className={className('pregnancy-new-container-title') + ' ' + className(`pregnancy-new-title-${index}`)}>{item.title}</h3>
                            <p
                                className={className('pregnancy-new-container-content')}
                                dangerouslySetInnerHTML={{
                                    __html: isMobile ? item.mobile_content : item.desktop_content,
                                }}
                            />
                        </div>
                        <div className={className('pregnancy-new-container-image')}>
                            {!item.videoUrl && <img src={item.imagePath[language]} alt="phone" />}

                            {item.videoUrl
                                && <div className={className('pregnancy-new-video-wrapper')}>
                                    <VideoRendering
                                        videoUrl={item.videoUrl[language]}
                                        cssStyles='pregnancy-new-video-wrapper-video'
                                        vidRef={vidRef}
                                    />
                                </div>}
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}

export default PregnancyNewContent