import feiaLogo from "./assets/logo.png";
import facebook from "./assets/facebook.svg";
import instagram from "./assets/instagram.svg";
import DClouds from "./assets/downClouds.png";
import pregnancyAppLogo from "./assets/pregnancy.png";
import weaningAppLogo from "./assets/weaning.png";
import developmentAppLogo from "./assets/development-2.png";
import pregnancyHeader from "./assets/feia_MOBILE.png";
import facebook2 from './assets/facebook (1).svg';
import instagram2 from './assets/instagram (1).svg';
import twitter from './assets/logo-x-twitter.svg';
import heart from './assets/love.svg';
import email from './assets/email.svg';
import blob1 from './assets/blob1.svg';
import blob2 from './assets/blob2.svg';
import blob3 from './assets/blob3.svg';
import blob4 from './assets/blob4.svg';
import childDevelopmnetBlob1 from './assets/childDevelopment-blob1.svg';
import childDevelopmnetBlob2 from './assets/childDevelopment-blob2.svg';
import childDevelopmnetBlob3 from './assets/childDevelopmentblob3.svg';
import childDevelopmnetBlob4 from './assets/childDevelopmnet-blob4.svg';
import childDevelopmntBlob5 from './assets/childDevelopmnet-blob5.svg';
import childDevelopmntBlob6 from './assets/childDevelopmnet-blob6.svg'
import oblaci from './assets/oblaci.png'
import fqa_arrow from './assets/fqa_arrow.png'
import faq_arrow_up from './assets/faq_arrow_up.png'
import Artwork from './assets/Artwork.png'
import closeButtonAds from './assets/Icons-Menu@2x.png';
import aboutUs_Artwork from './assets/aboutUs-Artwork.svg'
import fairy_with_questions from './assets/fairy_with_questions.png'
import instalirai_bezplatno from './assets/instalirai-bezplatno.png'
import instalirai_sega from './assets/instalirai-sega.png'
import install_now_mobile from './assets/install-now-mobile.png';
import fairyGif from './assets/fairy.gif'
import feiaNewLogo from './assets/feia-new-icon.png'
import newFeiaMobilePopup from './assets/new-feia-mobile-popup.png'
import carouselNewPregancnyLogo from './assets/carouselPreganncyLogo1.png'
import carouselPreganncyLogoWhite from './assets/carouselPreganncyLogoWhite.png'

//pregnancy slider images
import bremennostVideo from './assets/bremennost-video.mp4'
import slide1 from './assets/pregnancy slider/slide1.png'
import slide2 from './assets/pregnancy slider/slide2.png'
import slide3 from './assets/pregnancy slider/slide3.png'
import slide4 from './assets/pregnancy slider/slide4.png'
import slide5 from './assets/pregnancy slider/slide5.png'
import slide6 from './assets/pregnancy slider/slide6.png'
import slide7 from './assets/pregnancy slider/slide7.png'
import slide8 from './assets/pregnancy slider/slide8.png'
import slide9 from './assets/pregnancy slider/slide9.png'
import slide10 from './assets/pregnancy slider/slide10.png'
import slide11 from './assets/pregnancy slider/slide11.png'
import slide12 from './assets/pregnancy slider/slide12.png'
import slide13 from './assets/pregnancy slider/slide13.png'
import slide14 from './assets/pregnancy slider/slide14.png'
import slide15 from './assets/pregnancy slider/slide15.png'
import slide16 from './assets/pregnancy slider/slide16.png'
import slide17 from './assets/pregnancy slider/slide17.png'
import slide18 from './assets/pregnancy slider/slide18.png'
import slide19 from './assets/pregnancy slider/slide19.png'
import slide20 from './assets/pregnancy slider/slide20.png'
import slide21 from './assets/pregnancy slider/slide21.png'
import slide22 from './assets/pregnancy slider/slide22.png'
import slide23 from './assets/pregnancy slider/slide23.png'
import slide24 from './assets/pregnancy slider/slide24.png'
import slide25 from './assets/pregnancy slider/slide25.png'
import slide26 from './assets/pregnancy slider/slide26.png'
import slide27 from './assets/pregnancy slider/slide27.png'
import slide28 from './assets/pregnancy slider/slide28.png'
import slide29 from './assets/pregnancy slider/slide29.png'
import slide30 from './assets/pregnancy slider/slide30.png'
import slide31 from './assets/pregnancy slider/slide31.png'

import bulgarian_flag from './assets/Flag_of_Bulgaria.png';
import global_flag from './assets/Flag_of_the_United_Kingdom.png';
import germany_flag from './assets/Flag_of_Germany.png'

export let logo = feiaLogo;
export let facebookIcon = facebook;
export let instagramIcon = instagram;
export let downClouds = DClouds;
export let pregnancy = pregnancyAppLogo;
export let weaning = weaningAppLogo;
export let development = developmentAppLogo;
export let pregnancyPageHeader = pregnancyHeader;
export let faceBook2Icon = facebook2;
export let instagram2Icon = instagram2;
export let twitterIcon = twitter;
export let heartIcon = heart;
export let emailIcon = email;
export let firstBlob = blob1;
export let secondBlob = blob2;
export let thirdBlob = blob3;
export let fourthBlob = blob4
export let firstBlobChildDevelopment = childDevelopmnetBlob1
export let secondBlobChildDevelopment = childDevelopmnetBlob2
export let thirdBlobChildDevelopmnet = childDevelopmnetBlob3
export let fourthBlobChildDevelopmnet = childDevelopmnetBlob4
export let fifthBlobChildDevelopmnt = childDevelopmntBlob5
export let sixthBlobChildDevelopmnet = childDevelopmntBlob6

export let bremennostVideoSlider = bremennostVideo;
export let slider1 = slide1;
export let slider2 = slide2;
export let slider3 = slide3;
export let slider4 = slide4;
export let slider5 = slide5;
export let slider6 = slide6;
export let slider7 = slide7;
export let slider8 = slide8;
export let slider9 = slide9;
export let slider10 = slide10;
export let slider11 = slide11;
export let slider12 = slide12;
export let slider13 = slide13;
export let slider14 = slide14;
export let slider15 = slide15;
export let slider16 = slide16;
export let slider17 = slide17;
export let slider18 = slide18;
export let slider19 = slide19;
export let slider20 = slide20;
export let slider21 = slide21;
export let slider22 = slide22;
export let slider23 = slide23;
export let slider24 = slide24;
export let slider25 = slide25;
export let slider26 = slide26;
export let slider27 = slide27;
export let slider28 = slide28;
export let slider29 = slide29;
export let slider30 = slide30;
export let slider31 = slide31;

export let closeButton = closeButtonAds;
export let clouds = oblaci;
export let faq_Arrow = fqa_arrow;
export let faq_Arrow_up = faq_arrow_up;
export let artwork = Artwork;

export let bulgarianFlag = bulgarian_flag;
export let globalFlag = global_flag;
export let aboutUs_artwork = aboutUs_Artwork;
export let fairyWithQuestions = fairy_with_questions;
export let instaliraiBezplatno = instalirai_bezplatno;
export let instaliraiSega = instalirai_sega;
export let installNowMobile = install_now_mobile;
export let fairy_gif = fairyGif;
export let feiaNewIcon = feiaNewLogo;
export let newPopupImage = newFeiaMobilePopup
export let pregnancyNewLogo = carouselNewPregancnyLogo
export let preganncyLogoWhite = carouselPreganncyLogoWhite
export let germanyFlag = germany_flag