import { isMobile } from "react-device-detect";
import { className } from '../../Utils'

import StoreSection from '../storeSection/StoreSection';
import { useTranslations } from "../../lang/TranslationHooks";

import whiteArrow from '../../assets/installNowArrowWhite2.png'
import installNowArrowWhite from '../../assets/installNowArrowWhite.png'

import './AddPriceSection.css'
import InstallNow from "../InstallNow/InstallNow";

const AddPriceSection = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('add-price-wrapper')}>
            <div
                className={className('addPrice-left')}
            >
                <div className={className('addPrice-left-content')}
                    dangerouslySetInnerHTML={{
                        __html: staticTranslate('t_pregnancy_price_section_title')
                    }}
                >
                </div>

                <div className={className('add-price-left-storeSection')}>
                    <StoreSection />
                </div>
                <div className={className('addPrice-left-installNow')}>
                    <InstallNow
                        text={staticTranslate('t_pregnancy_price_section_left_install_free', true)}
                        animate={false}
                        isPriceSection={true}
                    />

                    <div className={className('install-now-arrow-container')}>
                        <img src={isMobile
                            ? installNowArrowWhite
                            : whiteArrow}
                            className='white-arrow'
                            alt='white arrow'
                            loading="lazy"
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPriceSection
