import React from 'react'
import OperationSystem from "../../../operationSystem/OperationSystem";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { logo } from '../../../../Images';
import gaiaLogo from '../../../../assets/GaiaLogo.png'

/* hide those components while the community page is not ready */
// import UserIcon from "../../../User/UserIcon/UserIcon";

/* hide AdvertisingCard while we don't have something special to present */
// import AdvertisingCard from '../../../AdvertisingCard/AdvertisingCard';

const MobileNavbar = ({ changeNavbarBg, currentHeight, home, onMenuClicked, menuIsOpened, pageName }) => {
    const { language } = useSelector((state) => state.home)
    return (
        <div
            className={changeNavbarBg
                ? "mobileNavbarWhite"
                : home ? "mobileMenu" : "mobileMenuPregnancy"}
        >
            <div
                id="hamburgerIcon"
                onClick={() => onMenuClicked()}
                className={menuIsOpened ? "openHamburgerIcon" : ""}
            >
                <span className={menuIsOpened ? "openHamburgerIcon" : ""} />
                <span className={menuIsOpened ? "openHamburgerIcon" : ""} />
                <span className={menuIsOpened ? "openHamburgerIcon" : ""} />
            </div>
            {/* <div> */}
            <Link to="/" className="centerMenuLogo-container">
                <img src={language === 'bg' ? logo : gaiaLogo} alt='logos' />
            </Link>
            {/* </div> */}
          

            {/* hide those components while the community page is not ready */}
            {/* {language === 'bg' && <UserIcon />} */}

            {
                !(pageName === 'home')
                && <OperationSystem
                    currentHeight={currentHeight}
                />
            }

            {/* hide AdvertisingCard while we don't have something special to present */}
            {/* {(pageName === "home" || pageName === "pregnancy") && (
                <AdvertisingCard currentHeight={currentHeight} pageName={pageName} />
            )} */}
            
            <div className={"searchButtonMobile"} />
        </div>
    )
}

export default MobileNavbar